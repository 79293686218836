<template>
  <div class="portal">
    <ease-app-drawer :actions="actions" :background-style="backgroundStyle" />

    <!-- 放入portal下children的component -->
    <v-main style="height: 100%;">
      <router-view />
    </v-main>

    <!-- footer -->
    <EaseFooter />
  </div>
</template>

<script>
// import EaseAppBar from '@/components/bar/EaseAppBar'
import EaseFooter from '@/components/bar/EaseFooter'
import EaseAppDrawer from '@/components/bar/EaseAppDrawer.vue'

export default {
  name: 'Portal',

  components: {
    // EaseAppBar,
    EaseFooter,
    EaseAppDrawer
  },

  data: (vm) => ({
    backgroundStyle: {
      backgroundImage: 'linear-gradient(to right, #1b7ec2, #00abeb)'
    }
  }),

  computed: {
    actions() {
      return [
        {
          text: this.$t('page.projects.title'),
          icon: 'mdi-format-list-bulleted-square',
          color: 'grey lighten-5',
          to: {
            name: 'Projects'
          }
        },
        // {
        //   text: this.$t('page.teams.title'),
        //   icon: 'mdi-account-multiple',
        //   color: 'grey lighten-5',
        //   to: {
        //     name: 'Teams'
        //   }
        // },
        {
          text: this.$t('page.files.title'),
          icon: 'mdi-folder-outline',
          color: 'grey lighten-5',
          to: {
            name: 'FileMgr'
          }
        },
        {
          text: this.$t('page.databases.title'),
          icon: 'mdi-database',
          color: 'grey lighten-5',
          to: {
            name: 'Databases'
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .portal {
    height: 100vh;
  }
</style>
